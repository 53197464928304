<template>
<div class="">
    <h4 class="ol-dashboard__container-title">Voucher Saya</h4>
    <section class="ol-checkout-couponcarousel">
        <div class="ol-wrapper ol-wrapper--large">
            <div id="myCoupon" class="modal-coupon" style="display:block;" v-show="modal.showing">

                <!-- Modal content -->
                <div class="modal-coupon-content" v-if="modal.data!=null">

                    <span class="close-coupon" v-on:click="()=>{ this.modal.showing=false; }">&times;</span>
                    <div class="row">
                        <div class="">
                            <div class="imgCoupon">
                                <img :srcset="modal.data.image" class="voucher-image" style="width: 100%;">
                            </div>

                            <div class="coupontitle">
                                <h1>Voucher <span class="voucher">{{renderPotongan(modal.data)}}</span></h1>
                                <h2 class="voucher-code">{{modal.data.code}}</h2>
                                <div style="display: flex;">
                                    <div style="display: flex; align-items: center;">
                                        <i class="fas fa-clock fa-sm"
                                            style=" margin-right: 10px; color: #364B7B;"></i>
                                    </div>

                                    <p>Berlaku Hingga </p>
                                    <strong class="voucher-expired"> {{ modal.data.expired }}</strong>

                                </div>
                            </div>


                            <div class="snk">
                                <h2>Syarat dan Ketentuan</h2>
                                <div class="voucher-snk" v-html=" modal.data.term_condition">
                                  {{ modal.data.term_condition }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="ol-checkout-couponcarousel-list" >
                <div class="ol-checkout-couponcarousel-list-slide" style="width:50%" v-for="voucher in data" :key="voucher.id">
                      <div class="ol-checkout-couponcarouselt-item">
                          <div class="card">
                              <a v-on:click="detailVoucher(voucher)" class="main" data-snk="" data-code="" :data-image="voucher.image">
                                  <img :srcset="voucher.image">
                              </a>
                              <div style="padding:10px;">
                                  <div class="copy-button">
                                      <div class="valid-thru">
                                          <div style="display: flex;">
                                              <div style="display: flex; align-items: center;">
                                                  <i class="fas fa-clock fa-lg"
                                                      style=" margin-right: 10px; color: #364B7B;"></i>
                                              </div>
                                              <div>
                                                  <p>Berlaku Hingga</p>
                                                  <strong> &nbsp; {{ voucher.expired }}</strong>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="">
                                          <button type="button" class="copybtn" v-on:click="gunakan(voucher)">GUNAKAN</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
            </div>
            <BannerBottomLoading v-if="loading" />
        </div>
    </section>
</div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import globalUrl from '@/util/globallUrl'
import helpme from '@/util/helpme'
import BannerBottomLoading from "@/components/loading/BannerBottomLoading";
export default {
    name:"DashboardVoucher",
    components:{
      BannerBottomLoading,
    },
    data(){
        return {
            loading: false,
            alertVoucher:{
              showing:false,
              pesan:'',
              type:''
            },
            data:[],
            modal:{
              showing:false,
              data:null,
            },
            input:{
              kode:'',
              data:null
            },
            queue:null,
        }
    },
    computed:{
      ...mapGetters("auth", ["authenticated"]),
      ...mapGetters("transaction/cart", ["variants","grandtotal","voucher","grandtotalVoucher","diskon"]),
      ...mapGetters("product/detail", ["recommended"]),
    },
  methods:{
    ...mapActions("transaction/cart", ["set_voucher"]),
    detailVoucher(data){
      this.modal.data = data;
      this.modal.showing=true;
    },
    async gunakan(data){
      this.input.kode = data.code;
    },
    async terapkan(){
        // console.log(this.input.kode);
        var y = document.getElementById("infopesan");
        

        let result = await this.set_voucher(this.input.kode);
        
        if(result.success){
          // console.log(result.success);
          this.alertVoucher.showing=true;
          this.alertVoucher.pesan ="Voucher berhasil dipakai"; 
          y.style.display = "flex";
          y.classList.remove("promoalertfail");
          
        }else{
          this.alertVoucher.showing=true;
          this.alertVoucher.pesan =result.data;
          y.classList.add("promoalertfail");
          y.style.display = "flex";     
        }
      
        if(!this.input.kode){
          var y = document.getElementById("infopesan");
          y.style.display = "none";
        }
  
    },
    renderPotongan(voucher){
      if(voucher.type_discount == 1){
        return helpme.toRp(voucher.voucher);
      }else if(voucher.type_discount == 2){
        return "Diskon "+voucher.voucher+"%";
      }
    },
    jq(){
        $(".ol-checkout-couponcarousel-list").slick({
          slidesToScroll: 1,
          slidesToShow: 3,
          dots: !1,
          autoplay: !0,
          autoplaySpeed: 5e3,
          arrows: false,
          pauseOnFocus: !1,
          infinite: !0,
          speed: 500,
          fade: !1,
          responsive: [{
              breakpoint: 891,
              settings: {
                  slidesToShow: 2
              }
          },
          {
              breakpoint: 600,
              settings: {
                  slidesToShow: 1
              }
          }]
      })
    },
    async getData(){
        this.loading=true;

        await globalUrl.WS_TRANSACTION.CART.GET_VOUCHER().then(response => {
            
            this.data = response.data.data;
            this.loading=false;
            
        }).catch(error => {
            // console.log(error.data)
            this.loading=false;
        });
        // this.jq();
        
    },
  },
  async mounted(){
    // console.log(this.variants);
    this.getData();
  }
}
</script>
<style scoped lang="css">
@import '../../assets/css/couponProfile.css';
</style>